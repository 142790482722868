.app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: start;
}

.app > .spacer {
    flex-grow: 1;
}

.app-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.app-header-logo {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.app-header .title {
    padding: 8px;
    font-size: 20px;
    font-weight: 900;
    color: #0F172A;

}

.app-header .header-icon {
    max-height: 48px;

}

.app-header > .spacer {
    flex-grow: 1;
}

.app-header > .header-text {
    color: #64748B;
    padding: 2em;
    padding-left: 1.5em;
}

.body {
    font-size: 1em;
    font-weight: normal;
    line-height: 1.5em;
}

a {
    display: block;
}

a:link {
    text-decoration: none;
    color: inherit;
}

a.external-link:link,
a.external-link:visited {
    text-decoration: underline;
    color: #007AFF;
}


.h1 {
    font-size: 2em;
    line-height: 1.25em;
    font-weight: 900;
    color: #0F172A;
    padding-bottom: 0.25em;
}

.h2 {
    font-size: 1.5em;
    line-height: 1.25em;
    font-weight: 600;
    color: #64748B;
}

.title-download {
    font-size: 1.5em;
    line-height: 1.25em;
    font-weight: 700;
    color: #0F172A;
    padding-top: 4em;
    padding-bottom: 0.5em;
    align-self: center;
    text-align: center;
}

.subtitle-download {
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: #64748B;
    align-self: center;
    text-align: center;
    padding-bottom: 1em;
}



.secondary-download-button {
    font-size: 1em;
    font-weight: 700;
    color: #007AFF;
    user-select: none;
    cursor: pointer;
}

.h3-download {
    font-size: 1.25em;
    font-weight: 700;
    color: #0F172A;
    padding-top: 5em;
    padding-bottom: 0.5em;

}



.header-text:hover {
    background: #F1F5F9;
    border-radius: 8px;
}

.hero-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: end;
    max-width: 400px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 72px;
}

.hero-image {
    max-width: 640px;
    width: 100%;
    height: auto;
}

.hero-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 80px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1440px;
    align-self: center;
}

.app-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: fit-content;
    height: fit-content;
    align-self: center;
}

.app-footer > .footer-text {
    color: #64748B;
    padding: 1em;
}

.footer-text:hover {
    background: #F1F5F9;
    border-radius: 8px;
}

.download-button {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 160px;
    height: auto;

}

.download-button img {
    width: 100%;
}

.primary-button {
    display: flex;
    flex-direction: row;
    max-width: 240px;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    color: white;
    background: #007AFF;
    padding: 12px 32px;
    gap: 16px;
    border-radius: 2em;
    user-select: none;
    cursor: pointer;
}

.primary-button:hover {
    display: flex;
    flex-direction: row;
    max-width: 240px;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    color: white;
    background: #0A84FF;
    padding: 12px 32px;
    gap: 16px;
    border-radius: 2em;
}



.legal {
    width: 100%;
    max-width: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 240px;

}

.title-legal {
    font-size: 2em;
    font-weight: 900;
    padding-top: 40px;
    color: #1E293B;

}

.date-legal {
    font-size: 1.25em;
    font-style: italic;
    padding-top: 0.5em;
    padding-bottom: 4em;
    color: #64748B;

}

.paragraph-title {
    font-size: 1.5em;
    font-weight: 700;
    padding-top: 2em;
    color: #1E293B;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
}

.legal-text {
    font-size: 1.25em;
    line-height: 1.5em;
    padding-top: 1em;
    padding-left: 16px;
    padding-right: 16px;

    font-weight: 500;
    color: #64748B;
    width: 100%;
}